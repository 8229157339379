import {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";

interface GlobalStateContextProps {
  index: number;
  setIndex: (index: number) => void;
  hasSeenGameChallenges: any;
  setHasSeenGameChallenges: (index: any) => any;
}

const GlobalStateContext = createContext<GlobalStateContextProps | undefined>(
  undefined
);

export const GlobalStateProvider = ({ children }: { children: ReactNode }) => {
  const [index, setIndex] = useState<number>(1);
  const [hasSeenGameChallenges, setHasSeenGameChallenges] =
    useState<String>("notSeen");

  useEffect(() => {
    if (hasSeenGameChallenges === "notSeen") {
      const hasSeenGameChallengesLocal = localStorage.getItem(
        "hasSeenGameChallenges"
      );
      if (hasSeenGameChallengesLocal) {
        setHasSeenGameChallenges(hasSeenGameChallengesLocal);
      }
    }
  }, [hasSeenGameChallenges]);

  return (
    <GlobalStateContext.Provider
      value={{
        index,
        setIndex,
        hasSeenGameChallenges,
        setHasSeenGameChallenges,
      }}
    >
      {children}
    </GlobalStateContext.Provider>
  );
};

export const useGlobalState = () => {
  const context = useContext(GlobalStateContext);
  if (!context) {
    throw new Error("useGlobalState must be used within a GlobalStateProvider");
  }
  return context;
};
